<template>
  <div class="content">
    <div class="content__party content__party--start">
      <h1 class="title title--big title--color">Документы</h1>
    </div>
    <form class="form" @submit.prevent="sendForm">
      <div class="form__content">
        <div class="form__part">
          <div class="form-group">
            <label>Свидетельство ИНН юридического лица</label>
            <FakeUploader v-if="form.inn_doc_url" @deleteFakePassport="removeInnFile" :photoUrl="form.inn_doc_url" />
            <uploader v-else :getFileHash="getFileInn" limit-files="1" :fileUrl="fileUrl" />
          </div>
        </div>
        <div class="form__part">
          <div class="form-group">
            <label>Свидетельство ОГРН</label>
            <FakeUploader v-if="form.ogrn_doc_url" @deleteFakePassport="removeOgrnFile" :photoUrl="form.ogrn_doc_url" />
            <uploader v-else :getFileHash="getFileOgrn" limit-files="1" :fileUrl="fileUrl" />
          </div>
        </div>
      </div>
      <div class="holder holder--end">
        <button class="button" type="submit" ref="submit">
          <span>Сохранить изменения</span>
          <img src="@/assets/img/preloader.svg" alt="" class="button__preloader"/>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Uploader from '@/components/Uploader';
import FakeUploader from '@/components/FakeUploader';

export default {
  name: 'Docs',
  components: {
    Uploader,
    FakeUploader,
  },
  data() {
    return {
      form: {},
      fileUrl: `${process.env.VUE_APP_API_URL}files`,
    };
  },
  created() {
    this.fetchPartner();
  },
  methods: {
    fetchPartner() {
      this.$store.dispatch('partner/profile/GET_DATA')
        .then(response => {
          if (response.status >= 400) {
            this.$notify({
              type: 'warn',
              title: 'Внимание!',
              text: 'Ошибка получения данных'
            });
          } else {
            this.form = response.data;
          }
        })
        .catch(error => {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: `Ошибка: ${ error.message }`
          });
        });
    },
    sendForm() {
      this.$refs.submit.classList.add('preload');
      const data = {
        inn_doc: this.form.inn_doc,
        ogrn_doc: this.form.ogrn_doc,
      };
      this.$store.dispatch('partner/profile/PATCH_DATA', data)
        .then(() => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'success',
            title: 'Уведомление',
            text: 'Данные успешно сохранены'
          });
        })
        .catch(error => {
          this.$refs.submit.classList.remove('preload');
          this.$notify({
            type: 'error',
            title: 'Ошибка',
            text: error.response.data
          });
        });
    },
    getFileInn(value) {
      this.form.inn_doc = value;
    },
    getFileOgrn(value) {
      this.form.ogrn_doc = value;
    },
    removeInnFile() {
      this.form.inn_doc = '';
      this.form.inn_doc_url = '';
    },
    removeOgrnFile() {
      this.form.ogrn_doc = '';
      this.form.ogrn_doc_url = '';
    }
  }
};
</script>
