<template>
  <vue-clip
    :options="clipOptions"
    :on-added-file="addedFile"
    :on-complete="completeFile"
    ref="vc"
    class="uploader"
  >
    <template slot="clip-uploader-body">
      <div class="uploader__files" v-if="uploadedFiles">
        <div class="uploader__file" v-for="(file, index) in uploadedFiles" :key="file.id">
          <div class="uploader__content">
            <div class="uploader__body">
              <a
                :href="file.file"
                target="_blank"
                class="uploader__avatar"
              >
                <img :src="file.file" alt="" />
              </a>
              <div class="uploader__details">
                <div class="uploader__col">
                  <a
                    :href="file.file"
                    target="_blank"
                    class="uploader__link"
                  >{{ file.file_name }}</a
                  >
                </div>
                <div class="uploader__col">
                  <button
                    type="button"
                    class="uploader__delete"
                    @click="$emit('remove-file', index)"
                  >
                    <img src="../assets/img/file/delete.svg" alt="delete" />
                    Удалить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uploader__files" ref="vc_files">
        <div class="uploader__file" v-for="(file, index) in files" :key="file.id">
          <div class="uploader__content">
            <div class="uploader__body">
              <a
                :href="file.customAttributes.link"
                target="_blank"
                class="uploader__avatar"
                v-if="file.dataUrl"
              >
                <img :src="file.dataUrl" alt="" />
              </a>
              <div class="uploader__details">
                <div class="uploader__col">
                  <a
                    :href="file.customAttributes.link"
                    target="_blank"
                    class="uploader__link"
                    >{{ file.name }}</a
                  >
                </div>
                <div class="uploader__col">
                  <div
                    class="uploader__percent"
                    v-if="file.status !== 'error' && file.status !== 'success'"
                  >
                    {{ file.progress }}%
                  </div>
                  <button
                    type="button"
                    class="uploader__delete"
                    @click="removedFile(file, index, $event)"
                  >
                    <img src="../assets/img/file/delete.svg" alt="delete" />
                    Удалить
                  </button>
                </div>
              </div>
            </div>
            <div
              class="uploader__progress"
              v-if="file.status !== 'error' && file.status !== 'success'"
            >
              <span
                class="uploader__indicator"
                :style="{ width: file.progress + '%' }"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="uploader__error" v-if="typeof message === 'string'">{{ message }}</div>
      <div class="uploader__error" v-if="typeof message === 'object'">{{ message.file[0] }}</div>
    </template>
    <template slot="clip-uploader-action" slot-scope="params">
      <div
        class="uploader__action"
        :class="{ 'uploader__action--dragging': params.dragging }"
      >
        <div class="uploader__message dz-message">
          <div
            class="uploader__simple"
            v-show="limitFiles <= 1 && limitFiles > files.length"
          >
            <img src="../assets/img/file/staple.svg" alt="" />
            <span>Прикрепить файл</span>
          </div>
          <div
            class="uploader__drop"
            v-show="limitFiles > 1 && limitFiles > files.length"
          >
            <p>Перетащите файлы сюда</p>
            <p>или <span>выберите</span> со своего компьютера</p>
          </div>
        </div>
      </div>
    </template>
  </vue-clip>
</template>

<script>
export default {
  name: 'Uploader',
  props: ['getFileHash', 'limitFiles', 'fileUrl', 'uploadedFiles'],
  data () {
    return {
      clipOptions: {
        url: this.fileUrl,
        maxFiles: {
          limit: this.limitFiles,
          message: `Вы не можете загружать больше ${this.limitFiles} ${this.limitFiles === 1 ? 'файла' : 'файлов'}`
        },
        acceptedFiles: {
          extensions: ['image/*', 'application/pdf'],
          message: 'Неподходящий формат файла'
        },
        maxFilesize: {
          limit: 2,
          message: 'Размер файла превышает допустимый'
        },
        uploadMultiple: false
      },
      files: [],
      fileHash: null,
      message: '',
    };
  },
  // mounted() {
  //   console.log(this.passportUrl);
  // },
  methods: {
    addedFile (file) {
      this.files.push(file);
    },
    completeFile (file, status, xhr) {
      if (file.status === 'error') {
        console.log(file);
        this.message = file.errorMessage;

        this.$refs.vc.removeFile(file);
        this.$refs.vc.files.splice(this.$refs.vc.files.indexOf(file), 1);
        this.files.splice(this.files.indexOf(file), 1);

        return false;
      }
      const response = JSON.parse(xhr.responseText);
      file.addAttribute('link', response.file);
      this.message = '';

      if (this.limitFiles > 1) {
        this.fileHash = [];
        this.fileHash.push(response.id);
      } else {
        this.fileHash = '';
        this.fileHash = response.id;
      }

      this.getFileHash(response.id, file.name);
    },
    removedFile (file, index, event) {
      this.$emit('remove-file', index);
      this.$refs.vc.removeFile(file);
      this.$refs.vc.files.splice(this.$refs.vc.files.indexOf(file), 1);
      this.files.splice(this.files.indexOf(file), 1);

      if (this.limitFiles > 1) {
        this.fileHash.splice(this.fileHash.indexOf(file), 1);
      } else {
        this.fileHash = '';
        event.target.closest('.uploader__file').remove();
      }
    }
  }
};
</script>
