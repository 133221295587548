<template>
  <div class="fake-uploader">
    <a :href="photoUrl" target="_blank" class="fake-uploader__avatar">
      <img :src="photoUrl" alt="passport image">
    </a>
    <div class="fake-uploader__details">
      <div class="fake-uploader__col">
        <a :href="photoUrl" target="_blank" class="fake-uploader__link">Посмотреть файл</a>
      </div>
      <div class="fake-uploader__col">
        <button type="button" @click="deletePassport" class="fake-uploader__delete">
          <img src="@/assets/img/file/delete.svg" alt="delete">
          <span>Удалить</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FakeUploader',
  props: ['photoUrl'],
  methods: {
    deletePassport() {
      this.$emit('deleteFakePassport');
    }
  }
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.fake-uploader
  display: flex
  align-items: center

.fake-uploader--readonly

  .fake-uploader__delete
    pointer-events: none
    opacity: 0.5

.fake-uploader__avatar
  overflow: hidden
  width: 100px
  height: 100px
  margin-right: 15px
  line-height: 0
  text-decoration: none
  transition: opacity 0.3s
  cursor: pointer

  img
    width: 100%
    height: 100%
    object-fit: cover

  &:hover
    opacity: 0.9

.fake-uploader__details
  display: flex
  justify-content: space-between
  width: calc(100% - 115px)

.fake-uploader__col
  display: flex
  align-items: center

  &:first-child
    width: 100%

  &:last-child
    flex-shrink: 0

.fake-uploader__link
  font-size: 18px
  font-weight: 600
  color: $color-theme
  text-decoration: none
  transition: color 0.3s

  &:hover
    color: #3DA5F9

  &:active
    color: #1981D5

.fake-uploader__delete
  display: flex
  align-items: center
  font-size: 18px
  font-weight: 600
  line-height: 0

  img
    width: 12px
    height: 12px
    margin-right: 15px

  &:hover
    transform: scale(1.05)
</style>